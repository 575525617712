<template>
  <content-layout :loading="loading" v-if="$route.name === 'customer'">
    <template v-for="groupItem in filtredGroups">
      <div
        class="text-h5 my-6 font-weight-semibold textPrimary--text"
        :key="groupItem.title"
      >
        {{ groupItem.title }}
      </div>
      <v-row :key="groupItem.id" class="mb-4">
        <v-col cols="auto" v-for="item in groupItem.cards" :key="item.title">
          <cz-dashboard-card
            :image-src="item.image"
            :title="item.title"
            :subtitle="item.subtitle"
            :count="item.count && item.count()"
            @click="
              item.handler
                ? item.handler()
                : item.to
                ? $router.push(item.to)
                : null
            "
          />
        </v-col>
      </v-row>
    </template>
    <invite-user-dialog
      v-if="inviteUserDialog.show"
      v-model="inviteUserDialog.show"
      v-bind="inviteUserDialog"
      @close="inviteUserDialog.show = false"
      @on-invite="inviteUser"
    />

    <review-order-dialog
      v-if="reviewOrderDialog.show"
      v-model="reviewOrderDialog.show"
      v-bind="reviewOrderDialog"
      @close="reviewOrderDialog.show = false"
      @on-save="saveOrder"
    />

    <export-submissions-dialog
      v-if="exportSubmissionsDialog.show"
      v-model="exportSubmissionsDialog.show"
      v-bind="exportSubmissionsDialog"
      @close="exportSubmissionsDialog.show = false"
      @on-export="exportSubmissions"
    />

    <new-order-type-dialog
      v-model="newOrderTypeDialog.show"
      v-bind="newOrderTypeDialog"
      @on-select="onOrderTypeSelected"
      @close="newOrderTypeDialog.show = false"
    />

    <new-order-dialog
      v-if="newOrderDialog.show"
      v-model="newOrderDialog.show"
      v-bind="newOrderDialog"
      @close="newOrderDialog.show = false"
      @continue="onNewOrderUploaded"
    />
  </content-layout>
  <router-view v-else />
</template>

<script>
import ordersActiveImage from '@/assets/illustrations/orders-active.svg';
import workflowManagementImage from '@/assets/illustrations/workflow-management.svg';
import signersImage from '@/assets/illustrations/signer.svg';
import ordersClosedImage from '@/assets/illustrations/orders-closed.svg';
import ordersCreateImage from '@/assets/illustrations/orders-create.svg';
import ordersCancelledImage from '@/assets/illustrations/vendors-rejected.svg';
import usersManagementImage from '@/assets/illustrations/users-management.svg';
import userInviteImage from '@/assets/illustrations/user-invite.svg';
import accountSettingsImage from '@/assets/illustrations/account-settings.svg';
import ordersPendingImage from '@/assets/illustrations/pending-approval.svg';
import invoicesNewImage from '@/assets/illustrations/invoices-new.svg';
import submissiosHandeled from '@/assets/illustrations/submissions-handeled.svg';
import catalogManagementImg from '@/assets/illustrations/catalog-management.svg';
import customization from '@/assets/illustrations/customization.svg';
import vendorsSearch from '@/assets/illustrations/vendors-search.svg';
import vendorsInProgress from '@/assets/illustrations/vendor-in-progress.svg';
import vendorsPending from '@/assets/illustrations/pending.svg';
import invoicesImage from '@/assets/illustrations/invoices.svg';
import automationsImage from '@/assets/illustrations/automations.svg';
import exportFile from '@/assets/illustrations/export-file.svg';
import dashboardsImage from '@/assets/illustrations/dashboards.svg';
import irsFile from '@/assets/illustrations/irs-file.svg';
import digitalArchive from '@/assets/illustrations/digital-archive.svg';
import NewOrderTypeDialog from '../components/NewOrderTypeDialog.vue';
import {
  createInvitation,
  countOrders,
  updateOrder,
  countOrderSubmissions,
  exportOrderSubmissions,
  uploadFiles,
  createOrder
} from '@/core/api';
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import {
  userHasOneOfRolesWithName,
  userHasRoleWithName
} from '@/shared/services/rbac/rbac.service';
import RoleType from '@/shared/types/RoleType';
import cloneDeep from 'lodash.clonedeep';
import { CzDashboardCard } from '@/components';
import OrderStatus from '@/shared/types/OrderStatus';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import { DOCUMENT_PREDICTION_TIMEOUT } from '@/domain/shared/constants';
import {
  isActiveFeatureEnabled,
  isDigitalArchiveEnabled
} from '@/shared/services/features.service';

export default {
  name: 'CustomerHomePage',
  components: {
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    InviteUserDialog: () => import('../components/InviteUserDialog.vue'),
    ReviewOrderDialog: () => import('../components/ReviewOrderDialog.vue'),
    NewOrderDialog: () => import('../components/NewOrderDialog.vue'),
    ExportSubmissionsDialog: () =>
      import('../components/ExportSubmissionsDialog.vue'),
    CzDashboardCard,
    NewOrderTypeDialog
  },
  computed: {
    ...mapFields('auth', [
      'account',
      'accountId',
      'abilities',
      'currentUser',
      'account.activeFeatures'
    ]),
    ...mapGetters('auth', [
      'isOrderManualCreationActive',
      'isOrderItemsActive'
    ]),
    // Return only groups and cards
    // that user can view based on the user roles
    filtredGroups() {
      const filteredGroups = [];

      // itereate on groups and remove all cards that user do not have
      // access to read
      this.groups.forEach((group) => {
        if (group.isVisible()) {
          const groupCopy = cloneDeep(group);
          // we reset cards because we need to add only visible cards
          groupCopy.cards = [];

          filteredGroups.push(groupCopy);

          group.cards.forEach((card) => {
            if (card.isVisible()) {
              groupCopy.cards.push(card);
            }
          });
        }
      });

      return filteredGroups;
    }
  },
  created() {
    this.countOrders();
  },
  data() {
    return {
      loading: false,
      inviteUserDialog: {
        show: false,
        loading: false
      },

      reviewOrderDialog: {
        show: false,
        order: null,
        orderAuthorizers: [],
        loading: false
      },
      exportSubmissionsDialog: {
        show: false,
        loading: false
      },
      newOrderTypeDialog: {
        show: false,
        orderItemsCreationIsActive: false
      },
      newOrderDialog: {
        show: false,
        loading: false,
        loadingTitle: '',
        title: this.$t('customer.createNewOrder'),
        inputLabel: this.$t('customer.orders.orderDocument'),
        uploadProgress: 0
      },
      groups: [
        {
          id: 'general',
          title: this.$t('customer.general'),
          isVisible: () => {
            return userHasOneOfRolesWithName(this.currentUser, [
              RoleType.CUSTOMER_OBSERVER
            ]);
          },
          cards: [
            {
              id: 'dashboards',
              title: this.$t('customer.analyticalDataTitle'),
              subtitle: this.$t('customer.analyticalDataSubtitle'),
              image: dashboardsImage,
              count: () => null,
              isVisible: () => false,
              to: {
                name: 'customer-analytics'
              }
            }
          ]
        },
        {
          id: 'business-management',
          title: this.$t('customer.workflowManagementTitle'),
          isVisible: () =>
            userHasOneOfRolesWithName(this.currentUser, [
              RoleType.CUSTOMER_ADMIN,
              RoleType.CUSTOMER_CATALOG_ADMIN
            ]),
          cards: [
            {
              id: 'order-submissions-approval-workflow',
              title: this.$t('customer.workflowsManagementCardTitle'),
              subtitle: this.$t('customer.workflowsManagementCardSubtitle'),
              image: workflowManagementImage,
              count: () => null,
              isVisible: () =>
                userHasOneOfRolesWithName(this.currentUser, [
                  RoleType.CUSTOMER_ADMIN
                ]),
              to: {
                name: 'customer-workflows-management'
              }
            },
            {
              id: 'customer-automations',
              title: this.$t('customer.manageAutomationsTitle'),
              subtitle: this.$t('customer.manageAutomationsSubtitle'),
              image: automationsImage,
              isVisible: () =>
                userHasOneOfRolesWithName(this.currentUser, [
                  RoleType.CUSTOMER_ADMIN
                ]),
              to: {
                name: 'customer-manage-automations'
              }
            },
            {
              id: 'manage-customer-catalog',
              title: this.$t('customer.manageCatalogTitle'),
              subtitle: this.$t('customer.manageCatalogSubtitle'),
              image: catalogManagementImg,
              count: () => null,
              isVisible: () =>
                userHasOneOfRolesWithName(this.currentUser, [
                  RoleType.CUSTOMER_CATALOG_ADMIN
                ]),
              to: {
                name: 'customer-catalog-management'
              }
            }
          ]
        },
        {
          id: 'orders',
          title: this.$t('customer.ordersTitle'),
          isVisible: () => {
            return (
              userHasRoleWithName(
                this.currentUser,
                RoleType.CUSTOMER_PURCHASER
              ) ||
              userHasRoleWithName(
                this.currentUser,
                RoleType.CUSTOMER_AUTHORIZER
              ) ||
              userHasRoleWithName(this.currentUser, RoleType.CUSTOMER_OBSERVER)
            );
          },
          cards: [
            {
              id: 'pending_orders',
              title: this.$t('customer.pendingOrders'),
              subtitle: this.$t('customer.pendingOrdersInfo'),
              image: vendorsPending,
              value: 'pending_orders',
              count: () => {
                if (this.ordersCount.new === -1) {
                  return '...';
                }

                return this.$t('customer.orders.ordersCount', [
                  this.ordersCount.new
                ]);
              },
              isVisible: () => {
                return userHasOneOfRolesWithName(this.currentUser, [
                  RoleType.CUSTOMER_PURCHASER
                ]);
              },
              to: {
                name: 'customer-orders'
              }
            },
            {
              id: 'pending_approval',
              title: this.$t('customer.pendingApprovalOrders'),
              subtitle: this.$t('customer.pendingApprovalOrdersInfo'),
              image: ordersPendingImage,
              value: 'pending_orders',
              count: () => {
                if (this.ordersCount.new === -1) {
                  return '...';
                }

                return this.$t('customer.orders.ordersCount', [
                  this.ordersCount.pendingApproval
                ]);
              },
              isVisible: () => {
                return userHasOneOfRolesWithName(this.currentUser, [
                  RoleType.CUSTOMER_AUTHORIZER,
                  RoleType.CUSTOMER_PURCHASER,
                  RoleType.CUSTOMER_OBSERVER
                ]);
              },
              to: {
                name: 'customer-orders',
                params: {
                  selectedTabId: 'pending-approval'
                }
              }
            },
            {
              id: 'active_orders',
              title: this.$t('customer.activeOrders'),
              subtitle: this.$t('customer.activeOrdersInfo'),
              image: ordersActiveImage,
              value: 'active_orders',
              count: () => {
                if (this.ordersCount.open === -1) {
                  return '...';
                }

                return this.$t('customer.orders.ordersCount', [
                  this.ordersCount.open
                ]);
              },
              isVisible: () => {
                return this.abilities.can('read', 'orders');
              },
              to: {
                name: 'customer-orders',
                params: {
                  selectedTabId: 'active-orders'
                }
              }
            },
            {
              id: 'closed_orders',
              title: this.$t('customer.closedOrders'),
              subtitle: this.$t('customer.closedOrdersInfo'),
              image: ordersClosedImage,
              value: 'closed_orders',
              count: () => {
                if (this.ordersCount.closed === -1) {
                  return '...';
                }

                return this.$t('customer.orders.ordersCount', [
                  this.ordersCount.closed
                ]);
              },
              isVisible: () => {
                return this.abilities.can('read', 'orders');
              },
              to: {
                name: 'customer-orders',
                params: {
                  selectedTabId: 'closed-orders'
                }
              }
            },
            {
              id: 'cancelled_orders',
              title: this.$t('customer.cancelledOrders'),
              subtitle: this.$t('customer.cancelledOrdersInfo'),
              image: ordersCancelledImage,
              value: 'cancelled_orders',
              count: () => {
                if (this.ordersCount.cancelled === -1) {
                  return '...';
                }

                return this.$t('customer.orders.ordersCount', [
                  this.ordersCount.cancelled
                ]);
              },
              isVisible: () => {
                return this.abilities.can('read', 'orders');
              },
              to: {
                name: 'customer-orders',
                params: {
                  selectedTabId: 'cancelled-orders'
                }
              }
            },
            {
              id: 'rejected-orders',
              title: this.$t('customer.rejectedOrders'),
              subtitle: this.$t('customer.rejectedOrdersInfo'),
              image: ordersCancelledImage,
              value: 'rejected_orders',
              count: () => null,
              isVisible: () => {
                return this.abilities.can('read', 'orders');
              },
              to: {
                name: 'customer-orders',
                params: {
                  selectedTabId: 'rejected-orders'
                }
              }
            },
            {
              id: 'create_order',
              title: this.$t('customer.createNewOrder'),
              subtitle: this.$t('customer.createNewOrderInfo'),
              image: ordersCreateImage,
              value: 'create_order',
              count: () => null,
              isVisible: () => {
                return this.abilities.can('create', 'orders');
              },
              handler: () => {
                if (this.isOrderManualCreationActive) {
                  this.newOrderTypeDialog.orderItemsCreationIsActive =
                    this.isOrderItemsActive;
                  this.newOrderTypeDialog.show = true;
                } else {
                  this.$router.push({
                    name: 'customer-orders',
                    params: {
                      newOrderModeType: 'upload-order'
                    }
                  });
                }
              }
            }
          ]
        },
        {
          id: 'observe-submissions',
          title: this.$t(
            'customer.vendorSubmissions.vendorSubmissionsObserveTitle'
          ),
          isVisible: () => {
            return userHasRoleWithName(
              this.currentUser,
              RoleType.CUSTOMER_OBSERVER
            );
          },
          cards: [
            {
              id: 'customer-observer-pending-submissions',
              title: this.$t(
                'customer.vendorSubmissions.pendingSubmissionsObserveTitle'
              ),
              subtitle: this.$t(
                'customer.vendorSubmissions.pendingSubmissionsObserveSubtitle'
              ),
              image: invoicesNewImage,
              count: () => null,
              isVisible: () => true,
              to: {
                name: 'customer-submissions'
              }
            },
            {
              id: 'customer-observer-handled-submissions',
              title: this.$t(
                'customer.vendorSubmissions.handeledSubmissionsObserverTitle'
              ),
              subtitle: this.$t(
                'customer.vendorSubmissions.handeledSubmissionsObserverSubtitle'
              ),
              image: submissiosHandeled,
              count: () => null,
              isVisible: () => true,
              to: {
                name: 'customer-submissions',
                params: {
                  selectedTab: 1
                }
              }
            },
            {
              id: 'export-submissions',
              title: this.$t('customer.vendorSubmissions.exportSubmissions'),
              subtitle: this.$t(
                'customer.vendorSubmissions.exportSubmissionsInfo'
              ),
              image: exportFile,
              count: () => null,
              isVisible: () => true,
              handler: () => {
                this.exportSubmissionsDialog.show = true;
              }
            }
          ]
        },
        {
          id: 'submissions',
          title: this.$t('customer.vendorSubmissions.vendorSubmissionsTitle'),
          isVisible: () => {
            return (
              userHasRoleWithName(
                this.currentUser,
                RoleType.CUSTOMER_AUTHORIZER
              ) ||
              userHasRoleWithName(this.currentUser, RoleType.CUSTOMER_PURCHASER)
            );
          },
          cards: [
            {
              id: 'customer-pending-submissions',
              title: this.$t(
                'customer.vendorSubmissions.pendingSubmissionsTitle'
              ),
              subtitle: this.$t(
                'customer.vendorSubmissions.pendingSubmissionsSubtitle'
              ),
              image: invoicesNewImage,
              count: () => {
                if (!this.orderSubmissionsCount) {
                  return '...';
                }

                return this.$t('customer.orders.orderSubmissionsCount', [
                  this.orderSubmissionsCount?.submissions || 0
                ]);
              },
              isVisible: () => true,
              to: {
                name: 'customer-submissions'
              }
            },
            {
              id: 'customer-handled-submissions',
              title: this.$t(
                'customer.vendorSubmissions.handeledSubmissionsTitle'
              ),
              subtitle: this.$t(
                'customer.vendorSubmissions.handeledSubmissionsSubtitle'
              ),
              image: submissiosHandeled,
              count: () => null,
              isVisible: () => true,
              to: {
                name: 'customer-submissions',
                params: {
                  selectedTab: 1
                }
              }
            },
            {
              id: 'invoices',
              title: this.$t('customer.manageInvoicesTitle'),
              subtitle: this.$t('customer.manageInvoicesSubtitle'),
              image: invoicesImage,
              isVisible: () =>
                userHasRoleWithName(
                  this.currentUser,
                  RoleType.CUSTOMER_PURCHASER
                ) &&
                isActiveFeatureEnabled(
                  'orderless',
                  this.account.activeFeatures
                ),
              to: {
                name: 'customer-submissions-orderless'
              }
            },
            {
              id: 'export-submissions',
              title: this.$t('customer.vendorSubmissions.exportSubmissions'),
              subtitle: this.$t(
                'customer.vendorSubmissions.exportSubmissionsInfo'
              ),
              image: exportFile,
              count: () => null,
              isVisible: () => true,
              handler: () => {
                this.exportSubmissionsDialog.show = true;
              }
            }
          ]
        },
        {
          id: 'user-management',
          title: this.$t('customer.userManagementTitle'),
          isVisible: () => {
            return (
              userHasOneOfRolesWithName(this.currentUser, [
                RoleType.CUSTOMER_ADMIN
              ]) || this.currentUser.email === 'itamars@rothschildcp.com'
            );
          },
          cards: [
            {
              id: 'users-management',
              title: this.$t('customer.usersManagement'),
              subtitle: this.$t('customer.usersManagementInfo'),
              image: usersManagementImage,
              value: 'users-management',
              to: {
                name: 'users-management'
              },
              isVisible: () => true
            },
            {
              id: 'user-invite',
              title: this.$t('customer.inviteUser'),
              subtitle: this.$t('customer.inviteUserInfo'),
              image: userInviteImage,
              value: 'user-invite',
              handler: () => {
                this.inviteUserDialog.allowedInvitationTypes = [
                  RoleType.CUSTOMER_ADMIN,
                  RoleType.CUSTOMER_AUTHORIZER,
                  RoleType.CUSTOMER_OBSERVER,
                  RoleType.CUSTOMER_PURCHASER,
                  RoleType.CUSTOMER_CATALOG_ADMIN
                ];
                this.inviteUserDialog.show = true;
              },
              isVisible: () => true
            },
            {
              id: 'account-settings',
              title: this.$t('customer.accountSettings'),
              subtitle: this.$t('customer.accountSettingsInfo'),
              image: accountSettingsImage,
              value: 'account-settings',
              to: {
                name: 'customer-account-settings'
              },
              isVisible: () => true
            },
            {
              id: 'customization',
              title: this.$t('customer.customization'),
              subtitle: this.$t('customer.customizationInfo'),
              image: customization,
              value: 'customization',
              to: {
                name: 'customer-account-customization'
              },
              isVisible: () => true
            },
            {
              id: 'irs-file',
              title: this.$t('customer.irsFile'),
              subtitle: this.$t('customer.irsFileInfo'),
              image: irsFile,
              value: 'irs-file',
              to: {
                name: 'irs-file'
              },
              isVisible: () => true
            }
          ]
        },
        {
          id: 'vendors',
          title: this.$t('customer.vendorsManagement'),
          isVisible: () => true,
          cards: [
            {
              id: 'vendors-list',
              title: this.$t('customer.vendorsList'),
              subtitle: this.$t('customer.vendorsListInfo'),
              image: vendorsSearch,
              count: () => null,
              isVisible: () => true,
              to: {
                name: 'customer-vendors-lookup',
                params: {
                  selectedTab: 0
                }
              }
            },
            {
              id: 'vendors-inprogress-list',
              title: this.$t('customer.vendorsListInProgress'),
              subtitle: this.$t('customer.vendorsListInProgressInfo'),
              image: vendorsInProgress,
              count: () => null,
              isVisible: () => true,
              to: {
                name: 'customer-vendors-lookup',
                params: {
                  selectedTab: 1
                }
              }
            },
            {
              id: 'vendors-pending-list',
              title: this.$t('customer.vendorsPendingList'),
              subtitle: this.$t('customer.vendorsPendingListInfo'),
              image: vendorsPending,
              count: () => null,
              isVisible: () => true,
              to: {
                name: 'customer-vendors-lookup',
                params: {
                  selectedTab: 2
                }
              }
            },
            {
              id: 'user-invite',
              title: this.$t('customer.vendorInvite'),
              subtitle: this.$t('customer.vendorInviteInfo'),
              image: userInviteImage,
              value: 'user-invite',
              isVisible: () => {
                return userHasOneOfRolesWithName(this.currentUser, [
                  RoleType.CUSTOMER_ADMIN,
                  RoleType.CUSTOMER_AUTHORIZER,
                  RoleType.CUSTOMER_PURCHASER
                ]);
              },
              handler: () => {
                this.inviteUserDialog.allowedInvitationTypes = [
                  RoleType.VENDOR_ADMIN
                ];
                this.inviteUserDialog.show = true;
              }
            }
          ]
        },
        {
          id: 'digital-archive',
          title: this.$t('customer.digitalArchiveTitle'),
          isVisible: () =>
            isDigitalArchiveEnabled(this.account) &&
            userHasOneOfRolesWithName(this.currentUser, [
              RoleType.CUSTOMER_ADMIN,
              RoleType.CUSTOMER_DIGITAL_ARCHIVE_VIEWER
            ]),
          cards: [
            {
              id: 'digital-archive',
              title: this.$t('customer.digitalArchiveViewTitle'),
              subtitle: this.$t('customer.digitalArchiveSubtitle'),
              image: digitalArchive,
              count: () => null,
              isVisible: () =>
                userHasOneOfRolesWithName(
                  this.currentUser,
                  RoleType.CUSTOMER_DIGITAL_ARCHIVE_VIEWER
                ),
              to: {
                name: 'customer-digital-archive'
              }
            },
            {
              id: 'digital-archive-signers',
              title: this.$t('customer.digitalArchiveSignersManagementTitle'),
              subtitle: this.$t(
                'customer.digitalArchiveSignersManagementSubtitle'
              ),
              image: signersImage,
              count: () => null,
              isVisible: () =>
                userHasOneOfRolesWithName(this.currentUser, [
                  RoleType.CUSTOMER_ADMIN
                ]) && isDigitalArchiveEnabled(this.account),
              to: {
                name: 'digital-archive-signers'
              }
            }
          ]
        }
      ],
      ordersCount: {
        new: -1,
        open: -1,
        closed: -1,
        cancelled: -1
      },
      orderSubmissionsCount: null
    };
  },
  methods: {
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),

    async countOrders() {
      const ordersGroup = this.groups.find((item) => item.id === 'orders');
      if (ordersGroup?.isVisible()) {
        this.ordersCount = await countOrders({
          accountId: this.accountId
        });
      }

      const submissionsGroup = this.groups.find(
        (item) => item.id === 'submissions'
      );
      if (submissionsGroup.isVisible()) {
        this.orderSubmissionsCount = await countOrderSubmissions({
          accountId: this.accountId
        });
      }
    },
    async inviteUser(data) {
      try {
        this.inviteUserDialog.loading = true;
        await createInvitation({
          inviteeEmail: data.email,
          // assignedRoles: [data.role.role],
          invitationType: data.role.invitationType,
          accountId: this.accountId
        });
        this.inviteUserDialog.show = false;
        this.showSuccessToastMessage(
          this.$t('invite.inviteSentSuccess', [data.email])
        );
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.inviteUserDialog.loading = false;
      }
    },
    onOrderTypeSelected(documentType) {
      this.newOrderTypeDialog.show = false;

      if (documentType === 'upload-order') {
        this.newOrderDialog.show = true;
      } else {
        this.$router.push({
          name: 'customer-order',
          params: {
            id: 'new',
            documentType
          }
        });
      }
    },
    async onNewOrderUploaded(orderDoc) {
      try {
        this.newOrderDialog.loading = true;
        this.newOrderDialog.loadingTitle = this.$t(
          'customer.orders.creatingOrder'
        );
        const [orderDocFile] = await uploadFiles([orderDoc], this.accountId);
        const order = await createOrder({
          orderDocumentFileId: orderDocFile._id,
          accountId: this.accountId,
          documentType: 'order',
          status: 'new'
        });

        this.newOrderDialog.loadingTitle = this.$t(
          'customer.orders.processingOrder'
        );
        // after order has been created, we need to wait about 4-5 seconds to order to be processed
        // In the future it will be done via messaging queue or via websockets.
        setTimeout(() => {
          this.handleProcessedOrder(order._id);
        }, DOCUMENT_PREDICTION_TIMEOUT);
      } catch (error) {
        this.newOrderDialog.loading = false;
        this.showErrorToastMessage(error.message);
      }
    },
    /**
     * Handle a proceseed order
     * We expect that the order will be in status new_predicted
     */
    async handleProcessedOrder(orderId) {
      this.newOrderDialog.show = false;
      this.$router.push({
        name: 'customer-order',
        params: {
          id: orderId
        }
      });
    },
    async saveOrder(order) {
      const _order = cloneDeep(order);
      if (
        order.status === OrderStatus.NEW ||
        order.status === OrderStatus.NEW_PREDICTED
      ) {
        // change order status to OPEN
        _order.status = OrderStatus.OPEN;
      }

      try {
        this.reviewOrderDialog.loading = true;
        // update the order
        await updateOrder(_order._id, _order);
        this.reviewOrderDialog.show = false;
        this.showSuccessToastMessage(
          this.$t('customer.orders.orderUpdateSuccess')
        );
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.reviewOrderDialog.loading = false;
      }
    },
    async exportSubmissions(data) {
      try {
        this.exportSubmissionsDialog.loading = true;
        const result = await exportOrderSubmissions({
          ...data,
          type: 'customer',
          accountId: this.accountId
        });
        const BOM = '\uFEFF';
        const csvData = BOM + result;
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        const fileName = `orders_submissions_${format(
          new Date(),
          'dd/MM/yyyy HH:mm:ss'
        )}`;
        saveAs(blob, fileName);
        this.exportSubmissionsDialog.show = false;
        this.showSuccessToastMessage(this.$t('common.downloadSuccessMessage'));
      } catch (error) {
      } finally {
        this.exportSubmissionsDialog.loading = false;
      }
    }
  },
  watch: {
    'newOrderDialog.show': {
      handler(value) {
        if (!value) {
          this.newOrderDialog.loading = false;
        }
      }
    },
    '$route.name': {
      handler(value) {
        if (value === 'customer') {
          this.countOrders();
        }
      }
    }
  }
};
</script>

<style></style>
